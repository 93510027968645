<script lang="ts" setup> 

    import { useTimeoutFn } from '@vueuse/core'

    const { fetchSettings, settings } = useSettings()
    await fetchSettings()

    const year = new Date().getFullYear()

    const links = settings.value.footer_primary_navigation_links

    const open = ref()

    const toggle = (uid:string) => {
      if (open.value != uid) {
        open.value = uid
      } else {
        open.value = null
      }
    }


    const name = ref('')
    const email = ref('')
    const success = ref(false)

    const { subscribe } = usePipedrive()

    const submit = async () => {

      const response = await subscribe(name.value, email.value)

      if(response.success) {

        name.value = ''
        email.value = ''

        success.value = true

        const { start } = useTimeoutFn(() => {
          success.value = false
        }, 8000)

        start()

      }

    }

</script>

<template>
  <footer class="bg-elephant-950">
    <div class="container max-w-screen-normal py-6">
      <div class="flex flex-col gap-6">
        
        <nuxt-link v-if="settings?.light_logo && settings?.light_logo?.filename" to="/" title="Home" aria-label="Home">
            <img 
              :src="settings?.light_logo?.filename" 
              :alt="settings?.light_logo?.alt"
              width="142"
              height="48"
              class="h-5 lg:h-6 w-auto"
              loading="lazy"
            >
        </nuxt-link>

        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-none xl:grid-flow-col gap-2 sm:gap-6">
            <div v-for="link in links" :key="link._uid" class="flex flex-col gap-2">
              <button @click="toggle(link._uid)" class="relative flex flex-col justify-center items-start min-h-4 w-auto pr-8 transition-colors ease-in-out duration-100 | font-hass-display text-h7_mobile md:text-h7_mobile lg:text-h7_mobile font-medium text-white-50">
                
                <span class="font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-medium text-white-50">
                  {{ link.footer_primary_navigation_link_title }}
                </span>

                <span class="toggle | flex sm:hidden absolute top-0 left-full -translate-x-full w-4 h-4 | after:content-[''] after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:w-2 after:h-2" :class="[open === link._uid ? 'after:rotate-0' : 'after:rotate-180']">
                </span>

              </button>
              <transition name="slide">
                <nav v-if="link.footer_secondary_navigation_links" class="sm:flex flex-col gap-2 mb-2 sm:mb-0" :class="[open === link._uid ? 'flex' : 'hidden']">
                  <nuxt-link v-for="sublink in link.footer_secondary_navigation_links" :key="sublink._uid" :to="`/${sublink.footer_secondary_navigation_link_url.cached_url}`" :title="sublink.footer_secondary_navigation_link_title" :aria-label="sublink.footer_secondary_navigation_link_title">
                    <span class="font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-roman text-white-50/50">
                      {{ sublink.footer_secondary_navigation_link_title }}
                    </span>
                  </nuxt-link>
                </nav>
              </transition>
            </div>
            <div class="sm:col-span-2 xl:col-span-1 | flex flex-col gap-2 mt-2 sm:mt-0">
              <span class="font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-medium text-white-50">
                Get updates from EPC
              </span>
              <ClientOnly>
                <form @submit.prevent="submit" class="flex flex-col gap-4">
                  <label for="name" class="font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-roman text-white-50/50">
                    Sign up for our newsletter to receive the most up-to-date information.
                  </label>
                  <div class="flex flex-col gap-1 sm:gap-2 sm:justify-start sm:items-start">
                    <input id="name" type="text" placeholder="Full Name *" v-model="name" required class="transition-colors ease-in-out duration-100 flex flex-col justify-center items-center font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-roman text-left bg-white-50/25 text-white-50 h-5 md:h-6 w-full px-4 rounded-full focus:outline focus:outline-lima-600 placeholder:text-white-50/50">
                    <input id="email" type="email" placeholder="Email Address *" v-model="email" required class="transition-colors ease-in-out duration-100 flex flex-col justify-center items-center font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-roman text-left bg-white-50/25 text-white-50 h-5 md:h-6 w-full px-4 rounded-full focus:outline focus:outline-lima-600 placeholder:text-white-50/50">
                    <button type="submit" title="Subscribe" aria-label="Subscribe" :disabled="name === '' || email === ''" class="transition-colors ease-in-out duration-100 flex flex-col justify-center items-center font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-roman text-center bg-lima-600 text-white-50 h-5 md:h-6 w-auto px-4 rounded-full disabled:bg-white-50/25 disabled:text-white-50/50 disabled:cursor-not-allowed">
                      Subscribe
                    </button>
                    <transition name="slide">
                      <p v-if="success" class="font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-roman text-white-50">Thank you for subscribing.</p>
                    </transition>
                  </div>
                </form>
              </ClientOnly>
            </div>
        </div>

        <div class="flex flex-wrap gap-4 md:gap-6 items-center">
          <a href="https://mcscertified.com/" title="#" target="_blank">
            <img
              src="~/assets/mcs-logo.png"
              alt="#"
              width="60"
              height="80"
              class="h-[40px] md:h-[60px] w-auto"
              loading="lazy"
            >
          </a>
          <a href="https://www.trustmark.org.uk/" title="#" target="_blank">
            <img
              src="~/assets/trustmark-logo.png"
              alt="#"
              width="237"
              height="50"
              class="h-[40px] md:h-[60px] w-auto"
              loading="lazy"
            >
          </a>
          <a href="https://www.fsb.org.uk/" title="#" target="_blank">
            <img
              src="~/assets/fsb-logo.png"
              alt="#"
              width="101"
              height="60"
              class="h-[40px] md:h-[60px] w-auto"
              loading="lazy"
            >
          </a>
          <a href="https://www.fca.org.uk/" title="#" target="_blank">
            <img
              src="~/assets/fca-logo.png"
              alt="#"
              width="190"
              height="60"
              class="h-[40px] md:h-[60px] w-auto"
              loading="lazy"
            >
          </a>
          <a href="https://www.recc.org.uk/" title="#" target="_blank">
            <img
              src="~/assets/recc-logo.png"
              alt="#"
              width="190"
              height="60"
              class="h-[40px] md:h-[60px] w-auto"
              loading="lazy"
            >
          </a>
        </div>

        <div class="pt-6 border-t border-white-50/20 flex flex-col lg:flex-row gap-6 justify-between items-start lg:items-center">
          <div>
            <span class="font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-roman text-white-50/50">
              &copy; {{ year }} EPC Improvements Ltd. All rights reserved.
            </span>
          </div>
          <div class="flex flex-row gap-[10px] items-start lg:items-center">
            <a href="https://www.facebook.com/epcimprovements/" title="Facebook" target="_blank">
              <img
                src="~/assets/icon_social-facebook.svg"
                alt="Facebook"
                width="32"
                height="32"
                class="w-4 h-4"
                loading="lazy"
              >
            </a>
            <!-- <a href="#" title="Twitter" target="_blank">
              <img
                src="~/assets/icon_social-twitter.svg"
                alt="#"
                width="32"
                height="32"
                class="w-4 h-4"
                loading="lazy"
              >
            </a> -->
            <a href="https://www.instagram.com/epc_improvements/" title="Instagram" target="_blank">
              <img
                src="~/assets/icon_social-instagram.svg"
                alt="Instagram"
                width="32"
                height="32"
                class="w-4 h-4"
                loading="lazy"
              >
            </a>
            <!-- <a href="#" title="#" target="_blank">
              <img
                src="~/assets/icon_social-youtube.svg"
                alt="#"
                width="32"
                height="32"
                class="w-4 h-4"
                loading="lazy"
              >
            </a> -->
            <a href="https://www.linkedin.com/company/epcimprovements/" title="LinkedIn" target="_blank">
              <img
                src="~/assets/icon_social-linkedin.svg"
                alt="LinkedIn"
                width="32"
                height="32"
                class="w-4 h-4"
                loading="lazy"
              >
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
  .toggle:after {
    background-image: url("data:image/svg+xml,%0A%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.564 8.116'%3E%3Cpath id='path-1' d='M9.678,1.1l8.029,6.5a.7.7,0,0,1,.1.984l-.173.215a.7.7,0,0,1-.985.1L9.184,2.855,1.717,8.9A.7.7,0,0,1,.8,8.874L.732,8.8.559,8.584a.7.7,0,0,1,.1-.984h0L8.691,1.1A.7.7,0,0,1,9.184.944.7.7,0,0,1,9.678,1.1Z' transform='translate(-0.403 -0.942)' style='fill:%23ffffff;fill-rule:evenodd'/%3E%3C/svg%3E");
    background-size:18px;
    background-position: center center;
    background-repeat: no-repeat;
  }
</style>
